import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Slivnitza” Blvd 141-143 Serdika region Bulgaria, 1233 Sofia`}</p>
    <form name="contact" method="POST" data-netlify="true" netlifyHoneypot="bot-field">
      <p className="hidden" style={{
        'display': 'none'
      }}>
    <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
  </p>
  <p>
    <label>Your Name: <input type="text" name="name" /></label>
  </p>
  <p>
    <label>Your Email: <input type="email" name="email" /></label>
  </p>
  <p>
    <label>Message: <textarea name="message"></textarea></label>
  </p>
  <p>
   <button sx={{
          variant: 'buttons.toggle',
          fontWeight: 'semibold',
          display: 'block',
          mx: 'auto',
          mb: 3
        }} type="submit" aria-label="Submit form">        Submit
        </button>
  </p>
    </form>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      