import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`OUR PROCESS`}</h2>
    <p>{`One of our most fundamental axioms is that the deep understanding of the business needs, goals and requirements is an indispensable prerequisite of Software Development.  In-depth discussions and regular brainstorming sessions help the team to identify in a clear and concise way the specifications of the software, throughout the full development life circle. Especially during the very first phase of any cooperation, we always produce a detailed specification analysis document that includes PROJECT CDR LTD’s understanding on both the technical and the business details of the project and we encourage our Client to read it and sign it off.`}</p>
    <p>{`Having identified the specifications, the team proceeds to the design phase. During this phase, the software is being designed both on the user interface level and the core code level through the use of standard software development methodologies. Once again, the client participates in the process by providing feedback for user interface layout samples and by answering all new questions that arise during the detailed designing of the code. Get in touch to learn more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      