import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img src="/cdr_project_logo.png" alt="CDR Project Logo" style={{
      "width": "100%",
      "maxWidth": "45vw",
      "margin": "auto",
      "display": "flex"
    }} />
    <h1>{`INTRODUCING PROJECT CDR`}</h1>
    <p>{`With our big experience in the sector, our ability to identify the special needs of any project and our highly educated and specialised team, PROJECT CDR LTD can help any Client by providing services all the way from Management to Development and QA.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      